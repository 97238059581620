@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap");

html,
body {
  font-family: "DM Sans", sans-serif;
  scroll-behavior: smooth;
  background: url("../assets/images/layout_bg.svg") !important;
}

center-absolute {
  position: absolute;
  left: 50%;
  right: 50%;
  transform: translate(-50%, -50%);
}

.zoom_image {
  animation: zoom-animate 2s infinite ease-in-out;
}

/* Loader */

@keyframes zoom-animate {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(2);
  }
  100% {
    transform: scale(1);
  }
}

.lds-ripple {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ripple div {
  position: absolute;
  border: 4px solid #fff;
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.lds-ripple div:nth-child(2) {
  animation-delay: -0.5s;
}
@keyframes lds-ripple {
  0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 0;
  }
  4.9% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 0;
  }
  5% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0px;
    left: 0px;
    width: 72px;
    height: 72px;
    opacity: 0;
  }
}

form.application_preview input {
  user-select: none !important;
  pointer-events: none !important;
  border-bottom: transparent;
}

form.application_preview select {
  user-select: none !important;
  pointer-events: none;
  border-bottom: transparent;
  background-image: none;
  padding: 0;
}

form.application_preview select + div {
  color: white;
}

form.application_preview textarea {
  user-select: none !important;
  pointer-events: none;
  border-bottom: transparent;
  background-color: #fff;
}

form.application_preview .chakra-checkbox, form.application_preview .chakra-radio {
  user-select: none !important;
  pointer-events: none !important;
}

form.application_preview small {
  display: none;
}

form.application_preview input[type="date"]::-webkit-calendar-picker-indicator {
  opacity: 0;
}